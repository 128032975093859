import React, { useEffect, useRef, useState } from 'react';

interface AutoScrollProps {
  children: React.ReactNode;
  scrollSpeed?: number; // pixels per second
  direction?: 'vertical' | 'horizontal';
  className?: string;
  pauseOnHover?: boolean;
}

const ScrollAutomatico: React.FC<AutoScrollProps> = ({
  children,
  scrollSpeed = 50,
  direction = 'vertical',
  className = '',
  pauseOnHover = true,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    if (!container || !content) return;

    let startTime: number;
    let animationFrameId: number;

    const scroll = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      
      if (direction === 'vertical') {
        container.scrollTop = (progress * scrollSpeed) / 1000;
        
        if (container.scrollTop >= content.offsetHeight - container.offsetHeight) {
          container.scrollTop = 0;
          startTime = timestamp;
        }
      } else {
        container.scrollLeft = (progress * scrollSpeed) / 1000;
        
        if (container.scrollLeft >= content.offsetWidth - container.offsetWidth) {
          container.scrollLeft = 0;
          startTime = timestamp;
        }
      }

      if (!isPaused) {
        animationFrameId = requestAnimationFrame(scroll);
      }
    };

    animationFrameId = requestAnimationFrame(scroll);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [scrollSpeed, direction, isPaused]);

  return (
    <div
      ref={containerRef}
      style={{ width: '100%' }}
      className={`overflow-hidden relative ${className}`}
      onMouseEnter={() => pauseOnHover && setIsPaused(true)}
      onMouseLeave={() => pauseOnHover && setIsPaused(false)}
    >
      <div ref={contentRef} className="inline-block">
        {children}
      </div>
    </div>
  );
};

export default ScrollAutomatico;