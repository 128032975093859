import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import ScrollAutomatico from '../../components/ScrollAutomatico';
import { IoArrowBack } from 'react-icons/io5';
import { BackButton, Card, DadosAtualizados, DashboardContainer, List, ListItem, Title, Value, ValueContainer, ValueMin, ValueSupervisor } from './styles';
import { Loading } from '../../components/Loading';

interface RotaData {
  total: number;
  emDeslocamento: number;
  andamento: number;
  concluidas: number;
  concluidoComPendencias: number;
  naoIniciadas: number;
  naoRealizadas: number;
}

interface Posto {
  name: string;
  statusRota: string;
}

interface Supervisor {
  nome: string;
}

interface Posto {
  name: string;
  statusRota: string;
}

interface HistoricoState {
  supervisor: Supervisor;
  historico: any[]; // Substitua `any[]` pelo tipo correto se houver estrutura definida
  postos: Posto[];
  total: number;
  andamento: number;
  emDeslocamento: number;
  concluidas: number;
  naoIniciadas: number;
  naoRealizadas: number;
  concluidoComPendencias: number;
}


const PainelMonitoramento = () => {
  const [dadosRotasAgrupados, setDadosRotasAgrupados] = useState({
    diurno: { total: 0, emDeslocamento: 0, andamento: 0, concluidas: 0, naoIniciadas: 0, naoRealizadas: 0, concluidoComPendencias: 0 },
    noturno: { total: 0, emDeslocamento: 0, andamento: 0, concluidas: 0, naoIniciadas: 0, naoRealizadas: 0, concluidoComPendencias: 0 },
    total: 0,
    andamento: 0,
    emDeslocamento: 0,
    concluidas: 0,
    naoIniciadas: 0,
    naoRealizadas: 0,
    concluidoComPendencias: 0,
  });

  const [dadosRotasSupervisor, setDadosRotasSupervisor] = useState({});
  const [dadosHistorico, setDadosHistorico] = useState<HistoricoState>({
    supervisor: { nome: "" },
    historico: [],
    postos: [{ name: "", statusRota: "" }],
    total: 0,
    andamento: 0,
    emDeslocamento: 0,
    concluidas: 0,
    naoIniciadas: 0,
    naoRealizadas: 0,
    concluidoComPendencias: 0,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return;

    let scrollSpeed = 1; // Ajuste a velocidade do scroll (pixels por intervalo)
    const intervalTime = 50; // Intervalo entre cada movimentação (milissegundos)

    const interval = setInterval(() => {
      if (scrollContainer) {
        scrollContainer.scrollTop += scrollSpeed;

        // Se chegou no final, volta para o topo
        if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
          scrollContainer.scrollTop = 0;
        }
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchData = () => {
      api.get('/postos/painel-monitoramento').then((response) => {
        const data = response.data;

        // Separar dados por turno
        const diurno = data.filter((item: any) => item.turno === 'Diurno');
        const noturno = data.filter((item: any) => item.turno === 'Noturno');

        // Função para calcular as estatísticas por turno
        const calcularEstatisticas = (dados: any[]) => ({
          total: dados.length,
          emDeslocamento: dados.filter((item: any) => item.Rota.status === 'Em Deslocamento' || item.Rota.status === 'Em deslocamento').length,
          concluidas: dados.filter((item: any) => item.Rota.status === 'Concluido').length,
          andamento: dados.filter((item: any) => item.Rota.status === 'Andamento').length +
            dados.filter((item: any) => item.Rota.status === 'No local').length,
          naoIniciadas: dados.filter((item: any) => item.Rota.status === 'Aprovada').length,
          naoRealizadas: dados.filter((item: any) => item.Rota.status === 'Não realizado').length,
          concluidoComPendencias: dados.filter((item: any) => item.Rota.status === 'Concluido com pendencias').length,
        });

        // Obter estatísticas separadas por turno
        const dadosDiurno = calcularEstatisticas(diurno);
        const dadosNoturno = calcularEstatisticas(noturno);

        setDadosRotasAgrupados({
          diurno: dadosDiurno,
          noturno: dadosNoturno,
          total: dadosDiurno.total + dadosNoturno.total,
          emDeslocamento: dadosDiurno.emDeslocamento + dadosNoturno.emDeslocamento,
          andamento: dadosDiurno.andamento + dadosNoturno.andamento,
          concluidas: dadosDiurno.concluidas + dadosNoturno.concluidas,
          naoIniciadas: dadosDiurno.naoIniciadas + dadosNoturno.naoIniciadas,
          naoRealizadas: dadosDiurno.naoRealizadas + dadosNoturno.naoRealizadas,
          concluidoComPendencias: dadosDiurno.concluidoComPendencias + dadosNoturno.concluidoComPendencias,
        });

        // Agrupar por supervisor e turno
        const groupedBySupervisor = response.data.reduce((acc: any, item: any) => {
          const supervisorId = item.Rota.supervisorId;
          if (!acc[supervisorId]) {
            acc[supervisorId] = {
              supervisor: item.Rota.Supervisor,
              historico: [],
              postos: [],
              total: 0,
              andamento: 0,
              emDeslocamento: 0,
              concluidas: 0,
              naoIniciadas: 0,
              naoRealizadas: 0,
              concluidoComPendencias: 0,
            };
          } else if (item.Historico?.length) {
          }

          acc[supervisorId].historico = acc[supervisorId].historico.concat(item.Historico);


          acc[supervisorId].total += 1;

          if (item.Rota.status === "Em Deslocamento" || item.Rota.status === 'Em deslocamento') {
            acc[supervisorId].emDeslocamento += 1;
          } else if (item.Rota.status === "Concluido") {
            item.Posto = { ...item.Posto, statusRota: 'Concluido' };
            acc[supervisorId].concluidas += 1;
          } else if (item.Rota.status === "Andamento" || item.Rota.status == "No local") {
            item.Posto = { ...item.Posto, statusRota: 'Andamento' };
            acc[supervisorId].andamento += 1;
          } else if (item.Rota.status === "Não realizado") {
            item.Posto = { ...item.Posto, statusRota: 'Não realizado' };
            acc[supervisorId].naoRealizadas += 1;
          } else if (item.Rota.status === "Aprovada") {
            item.Posto = { ...item.Posto, statusRota: 'Aprovada' };
            acc[supervisorId].naoIniciadas += 1;
          } else if (item.Rota.status === "Concluido com pendencias") {
            item.Posto = { ...item.Posto, statusRota: 'Concluido com pendencias' };
            acc[supervisorId].concluidoComPendencias += 1;
          } else {
            item.Posto = { ...item.Posto, statusRota: 'Não iniciada' };
            acc[supervisorId].naoIniciadas += 1;
          }

          acc[supervisorId].postos = acc[supervisorId].postos.concat(item.Posto);


          return acc;
        }, {});

        setIsLoading(false)

        setDadosRotasSupervisor(groupedBySupervisor);
      });

    };

    fetchData();

    // const interval = setInterval(fetchData, 10000);

    // return () => clearInterval(interval);
  }, []);

  const onClickCard = (supervisorData: any) => {
    console.log(supervisorData);
    setDadosHistorico(supervisorData)
  }

  interface RotaListProps {
    dadosRotasAgrupados: {
      diurno: RotaData;
      noturno: RotaData;
    } & RotaData;
  }

  const RotaList: React.FC<RotaListProps> = ({ dadosRotasAgrupados }) => {
    const categorias = [
      { label: "Total", key: "total" },
      { label: "Em Deslocamento", key: "emDeslocamento" },
      { label: "Em Andamento", key: "andamento" },
      { label: "Concluídas", key: "concluidas" },
      { label: "Concluídas c/ pendências", key: "concluidoComPendencias" },
      { label: "Não Iniciadas", key: "naoIniciadas" },
      { label: "Não Realizadas c/ justificativa", key: "naoRealizadas" },
    ] as const;

    return (
      <List>
        {categorias.map(({ label, key }) => (
          <ListItem key={key}>
            {label}:
            <ValueContainer>
              <Value>{dadosRotasAgrupados[key]}</Value>
              <ValueMin>
                Diurno: {dadosRotasAgrupados.diurno[key]} | Noturno: {dadosRotasAgrupados.noturno[key]}
              </ValueMin>
            </ValueContainer>
          </ListItem>
        ))}
      </List>
    );
  };


  interface SupervisorCardProps {
    supervisorData: HistoricoState;
    onClick?: () => void;
    isFullWidth?: boolean;
  }

  const SupervisorCard: React.FC<SupervisorCardProps> = ({ supervisorData, onClick, isFullWidth = false }) => {
    return (
      <Card
        onClick={onClick}
        style={{ gridColumn: isFullWidth ? "3 / span 8" : "span 6" }}
      >
        <Title>{supervisorData.supervisor.nome.toUpperCase()}</Title>
        <List>
          <ListItem>
            <span style={{ fontSize: 23 }}>
              Total: <ValueSupervisor>{supervisorData.total}</ValueSupervisor>
            </span>
            &nbsp;|&nbsp;
            <span style={{ fontSize: 23 }}>
              Em Deslocamento: <ValueSupervisor>{supervisorData.emDeslocamento}</ValueSupervisor>
            </span>
            &nbsp;|&nbsp;
            <span style={{ fontSize: 23 }}>
              Concluídas: <ValueSupervisor>{supervisorData.concluidas}</ValueSupervisor>
            </span>
            &nbsp;|&nbsp;
            <span style={{ fontSize: 23 }}>
              Não Iniciadas: <ValueSupervisor>{supervisorData.naoIniciadas}</ValueSupervisor>
            </span>
          </ListItem>
        </List>
      </Card>
    );
  };


  return (
    <ScrollAutomatico
      scrollSpeed={50}
      direction="vertical"
      className="h-64 w-full"
      pauseOnHover={true}
    >
    {isLoading && <Loading />}
      {dadosHistorico.supervisor.nome === "" ? (
        <DashboardContainer ref={containerRef}>
          {/* Card de Rotas */}
          <Card style={{ gridColumn: "4 / span 6" }}>
            <Value>Rotas</Value>
            <RotaList dadosRotasAgrupados={dadosRotasAgrupados} />
            <DadosAtualizados>
              Dados atualizados em: {new Date().toLocaleString()}
            </DadosAtualizados>
          </Card>

          {/* Cards dos Supervisores */}
          {Object.values(dadosRotasSupervisor).map((supervisorData: any) => (
            <SupervisorCard
              key={supervisorData.supervisor.id}
              supervisorData={supervisorData}
              onClick={() => onClickCard(supervisorData)}
            />
          ))}
        </DashboardContainer>
      ) : (
        <DashboardContainer>
          {/* Supervisor Selecionado */}
          <BackButton onClick={() => {setDadosHistorico({ supervisor: { nome: "" }, historico: [], postos: [], total: 0, andamento: 0, emDeslocamento: 0, concluidas: 0, naoIniciadas: 0, naoRealizadas: 0, concluidoComPendencias: 0 })}}>
            <IoArrowBack size={20} />
            Voltar
          </BackButton>
          <SupervisorCard supervisorData={dadosHistorico} isFullWidth />

          {/* Lista de Postos */}
          <Card style={{ gridColumn: "3 / span 8" }}>
            <List>
              {dadosHistorico.postos.map((posto) => (
                <ListItem posto={true} key={posto.name} status={posto.statusRota}>
                  {posto.name.toUpperCase()}
                  <ValueMin>{posto.statusRota}</ValueMin>
                </ListItem>
              ))}
            </List>
          </Card>
        </DashboardContainer>
      )}
    </ScrollAutomatico>
  );

};

export default PainelMonitoramento;
