import React, { useCallback } from 'react';

import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useAuth } from '../hooks/auth';
import { updateDrawerState } from '../store/modules/drawer/actions';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  permissao?: string;
  Setor?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  permissao,
  ...rest
}) => {
  const { user } = useAuth();
  
  const dispatch = useDispatch();

  const hendleUpdateDrawer = useCallback(
    (state: boolean) => {
      dispatch(updateDrawerState(state));
    },
    [dispatch],
  );

  const temPermissao = permissao ? user?.perfil?.permissoes.some(
    (p) => p.nome === permissao
  ) : true;

  if (!temPermissao) {
    return (<Redirect to="/acesso-negado" />);
  }


  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return ((isPrivate === !!user) && temPermissao) ? (
          <>
            {isPrivate && hendleUpdateDrawer(true)}
            <Component />
          </>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboards',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
