import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Clientes } from '../../models/Clientes';


interface ClienteMultiSelectProps {
  clientes: Clientes[];
  clienteIdSelect: number[];
  onChange: (selectedIds: number[]) => void;
}

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  font-family: Arial, sans-serif;
`;

const DropdownHeader = styled.div`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 10;
`;

const DropdownItem = styled.li<{ selected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#007bff" : "white")};
  color: ${({ selected }) => (selected ? "white" : "black")};
  &:hover {
    background-color: ${({ selected }) => (selected ? "#0056b3" : "#f0f0f0")};
  }
`;

const SelectClients: React.FC<ClienteMultiSelectProps> = ({
  clientes,
  clienteIdSelect,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Fecha o dropdown ao clicar fora
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (id: number) => {
    let newSelection: number[];
    if (id === -1) {
      // Se clicar em "Todos os clientes", seleciona/deseleciona todos
      newSelection = clienteIdSelect.length === clientes.length ? [] : clientes.map(c => parseInt(c.id));
    } else {
      newSelection = clienteIdSelect.includes(id)
        ? clienteIdSelect.filter(item => item !== id)
        : [...clienteIdSelect, id];
    }
    onChange(newSelection);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownHeader onClick={toggleDropdown}>
        {clienteIdSelect.length > 0
          ? `${clienteIdSelect.length} selecionado(s)`
          : "Selecione clientes"}
        <span>▼</span>
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          <DropdownItem
            selected={clienteIdSelect.length === clientes.length}
            onClick={() => handleSelect(-1)}
          >
            Todos os clientes
          </DropdownItem>
          {clientes.map(cliente => (
            <DropdownItem
              key={cliente.id}
              selected={clienteIdSelect.includes(parseInt(cliente.id))}
              onClick={() => handleSelect(parseInt(cliente.id))}
            >
              {cliente.name}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default SelectClients;
