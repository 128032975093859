import React from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/Signup';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SingUpSupervisor from '../pages/SignupSupervisor';
import Profile from '../pages/Profile';
import DashboardRondas from '../pages/DashboardRondas';
import Dashboard from '../pages/Dashboard';
import DashboardOcorrencias from '../pages/DashboardOcorrencias';
import Setores from '../pages/CadastroSetores';
import Categorias from '../pages/CadastroCategorias';
import CadastroRisco from '../pages/CadastroRiscos';
import UserAccess from '../pages/UserAccess';
import UploadPost from '../pages/UploadPost';
import CadastroAmbiente from '../pages/CadastroRonda';
import CadastroOcorrencia from '../pages/CadastroOcorrencia';
import CadastroRotas from '../pages/CadastroRotas';

// import CadastroAgendamento from '../pages/CadastroAgendamento';
import TermoConsentimento from '../pages/Termo';
import CadastroCliente from '../pages/CadastroClienteTeste';
import CadastroVeiculo from '../pages/CadastroVeiculo';
import CadastroDemanda from '../pages/CadastroDemanda';
import CadastroApontamento from '../pages/CadastroApontamento';
import CadastroOcorrenciaSupervisor from '../pages/CadastroOcorrenciaSupervisor';
// import AcessoBloqueado from '../pages/AcessoBloqueado';
import PreCadastroUsuarios from '../pages/PreCadastroUsuarios';
import ListagemUsuarios from '../pages/ListagemUsuarios';
import CadastroFilial from '../pages/CadastroFilial';
// import MapDashboard from '../pages/MapsDashboard2';
import CadastroEmpresa from '../pages/CadastroEmpresa';
import { DashboardAgendamentoRota } from '../pages/DashboardAgendamentoRota';
import { DetailsRota } from '../pages/DetailsRota';
import { Agendamentos } from '../pages/CadastroAgendaRonda';
// import { MapsDashboard } from '../pages/MapsDashboardNew';
// import CadastroVistorias from '../pages/CadastroVistorias';
import Apontamentos from '../pages/Apontamentos';
import HistoricPatrol from '../pages/HistoricPatrol';
import DashboardRotas from '../pages/DashboardRotas';
import DetailsRotaAprov from '../pages/DetailsRotaAprov';
import { AcompanhamentoClientes } from '../pages/AcompanhamentoClientes';
import { RelatorioVisitas } from '../pages/RelatorioVisitas';
import { MapsRotas } from '../pages/MapsRotas';
import { AuditoriaRonda } from '../pages/Auditoria';
import { AuditoriaRota } from '../pages/AuditoriaRota';
import { Privacidade } from '../pages/Privacidade';
import { Roteirizacao } from '../pages/Roteirizacao';
import PainelMonitoramento from '../pages/PainelMonitoramento';

const Routes: React.FC = () => {
  const { acesso } = useAuth();

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/politica-privacidade" component={Privacidade} />
      <Route path="/signup-supervisor" component={SingUpSupervisor} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/reset-password" component={ResetPassword} isPrivate />
      <Route path="/dashboard-rondas" component={DashboardRondas} isPrivate />
      <Route path="/dashboards" component={Dashboard} isPrivate permissao='acesso' />
      <Route
        path="/rotasTeste"
        component={DashboardAgendamentoRota}
        isPrivate
      />
      <Route path="/rotas" component={DashboardRotas} isPrivate permissao='rotas'/>
      <Route
        path="/rotas-clientes"
        component={AcompanhamentoClientes}
        isPrivate
      />
      <Route path="/relatorio-visitas" component={RelatorioVisitas} isPrivate />
      <Route path="/roteirizacao" component={Roteirizacao} isPrivate permissao='roteirizacao'/>

      <Route path="/detalhes-rota" component={DetailsRota} isPrivate />
      <Route
        path="/detalhes-rota-aprov"
        component={DetailsRotaAprov}
        isPrivate
      />
      <Route
        path="/dashboard-vistoria-rondas"
        component={DashboardOcorrencias}
        isPrivate
      />
      <Route path="/setor" component={Setores} isPrivate />
      <Route path="/categoria" component={Categorias} isPrivate />
      <Route path="/historic-patrol" component={HistoricPatrol} isPrivate />

      <Route path="/riscos-rondas" component={CadastroRisco} isPrivate />
      <Route path="/user-access" component={UserAccess} isPrivate />
      <Route path="/upload-post" component={UploadPost} isPrivate />
      <Route path="/rondas" component={CadastroAmbiente} isPrivate />
      <Route path="/auditoria-rondas" component={AuditoriaRonda} isPrivate />
      <Route path="/auditoria-rotas" component={AuditoriaRota} isPrivate />
      <Route
        path="/cadastro-ocorrencia-plantao"
        component={CadastroOcorrencia}
        isPrivate
      />
      <Route path="/cadastro-rotas" component={CadastroRotas} isPrivate />
      {/* <Route path="/rotas" component={CadastroAgendamento} isPrivate /> */}
      <Route
        path="/cadastro-cliente-posto"
        component={CadastroCliente}
        isPrivate
        permissao='cadastro-posto'
      />
      <Route path="/cadastro-veiculo" component={CadastroVeiculo} isPrivate permissao='veiculos' />
      <Route
        path="/cadastro-ocorrencia-supervisor"
        component={CadastroOcorrenciaSupervisor}
        isPrivate
      />
      <Route path="/cadastro-demanda" component={CadastroDemanda} isPrivate />
      <Route
        path="/cadastro-apontamento"
        component={CadastroApontamento}
        isPrivate
      />
      {/* <Route path="/acesso-bloqueado" component={AcessoBloqueado} /> */}
      <Route path="/MapsDashboard" component={MapsRotas} isPrivate />
      <Route path="/termo-de-consentimento" component={TermoConsentimento} />
      <Route path="/listagem-usuarios" component={ListagemUsuarios} isPrivate />

      <Route path="/cadastro-matriz" component={CadastroEmpresa} isPrivate />
      <Route path="/agenda-ronda" component={Agendamentos} isPrivate permissao='agenda-ronda'/>
      <Route path="/apontamentos" component={Apontamentos} isPrivate />
      <Route path="/painel-monitoramento" component={PainelMonitoramento} isPrivate permissao='monitoramento' />

      {acesso ? (
        <>
          <Route path="/dashboards" component={Dashboard} isPrivate />
          <Route
            path="/pre-cadastro-usuarios"
            component={PreCadastroUsuarios}
            isPrivate
            permissao='cadastro-usuario'
          />

          <Route path="/cadastro-filial" component={CadastroFilial} isPrivate permissao='cadastro-filial' />
        </>
      ) : (
        <>
          <Route
            path="/pre-cadastro-usuarios"
            component={PreCadastroUsuarios}
            isPrivate
            permissao='cadastro-usuario'
          />
          <Route path="/cadastro-filial" component={CadastroFilial} isPrivate permissao='cadastro-filial' />
        </>
      )}
    </Switch>
  );
};

export default Routes;
