import styled from 'styled-components';

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* font-family: Montserrat; */
`;

export const ContainerIframe = styled.div`
  width: 100%;
  // height: 100vh;
  max-height: 100vh;
  margin-top: 26px;
  border-radius: 8px;

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

export const BtnAdd = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 90%;
  padding: 5px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.primary};
  border: none;

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  p {
    margin: 0px;
    padding: 0px;
    color: ${(props) => props.theme.colors.white};
    font-family: 'Montserrat', sans-serif;
  }
`;

export const BackButton = styled.button`
  display: flex;
  margin-top: 20px;
  height: 40px;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background: linear-gradient(135deg,rgb(124, 124, 124) 0%, #999999 100%);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(216, 216, 216, 0.3);

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(135deg,rgb(65, 65, 65) 0%, #999999 100%);
    box-shadow: 0px 6px 15px rgba(199, 199, 199, 0.4);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0px 2px 5px rgba(194, 194, 194, 0.3);
  }
`;

export const DashboardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 12 colunas */
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9; /* Cor de fundo mais suave */
  border-radius: 8px; /* Bordas arredondadas para suavizar o layout */
`;

// Cartão individual de informações
export const Card = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 20px;
  &:hover {
    transform: translateY(-5px); /* Efeito de elevação ao passar o mouse */
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  }
`;

// Título dentro de cada cartão
export const Title = styled.h2`
  font-size: 28px;
  color: #333;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
`;

// Valor destacado dentro do cartão
export const Value = styled.div`
  text-align: right;
  font-size: 28px;
  font-weight: 700;
  color: #007bff;
  font-family: 'Roboto', sans-serif;
`;

export const ValueSupervisor = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  color: #007bff;
  font-family: 'Roboto', sans-serif;
`;

export const ValueMin = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
`;

export const ValueContainer = styled.p`
`;

// Lista de informações adicionais dentro do cartão
export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: 'Roboto', sans-serif;
`;

interface ListItemProps {
  status?: string;
  posto?: boolean;
}
export const ListItem = styled.li<ListItemProps>`
  background: ${(props) => (props.status == 'Aprovada' ? "#ccff0045" : props.status == 'Aprovada' ? "#ccff0045" :
    props.status == 'Em Deslocamento' ? "#ffcc0045" : props.status == 'Andamento' ? "#ffcc0045" : props.status == 'Concluido' ?
      "#00ff0045" : props.status == 'Concluido com pendencias' ? "#ff000045" : props.status == 'Não realizado' ? "#ff000045" : "#ffffff")};
  font-size: 28px;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border-radius: 6px;
  margin: 6px 0;
  border-bottom: 1px solid #f0f0f0;
  color: #666;
  height: ${(props) => (props.posto ? "" : "100px")};
  span {
    font-weight: 600;
    color: #333;
  }
`;

export const DadosAtualizados = styled.i`
  color: black;
`;