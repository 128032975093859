import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import jsPDF from 'jspdf';
import { FiRefreshCw } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Table from '../../components/Table';
import PaginationComponent from '../../components/Pagination';
import NewImportExel from '../../components/NewImportExel';
import { GetRotaComplete } from '../../models/Rota';
import { UsuarioBD } from '../../models/User';
import { Clientes } from '../../models/Clientes';
import ModalConexao from '../../components/ModalConexao';

import {
  Container,
  TitlePage,
  Filter,
  Options,
  Actions,
  ButtonPDF,
  Animeted,
  ContainerInputDate,
} from './styles';
import SelectClients from '../../components/SelectClients';

interface ItemTable {
  postoId: number;
  postoName: string;
  userName: string;
  atividade: string;
  status: string;
  observacao: string;
  justificativa: string;
  statusRota: string;
  dateAprov: string;
  userIdAprov: number;
  userNameAprov: string;
  dataMarcada: string;
  dataInicio: string;
  dataFinal: string;
  kmInicial?: string;
  kmFinal?: string;
  dataInicioPercurso?: string;
  dataFinalPercurso?: string;
  kmTotal?: string;
  imagem: string;
  userId: number;
  visitaId: number;
  clientId: number;
  clientName: string;
  descricao: string;
}

interface ItemPDF {
  visitaId: number;
  postoName: string;
  dataMarcada: string;
  dataAprov: string;
  userAprov: string;
  status: string;
  Tarefas: {
    name: string;
    init: string;
    fin: string;
    justificativa: string;
    obervacao: string;
    status: string;
    userName: string;
  }[];
}

const headers = [
  { name: 'Id Visita', field: 'visitaId', sortable: false },
  { name: 'Cliente', field: 'clientName', sortable: true },
  { name: 'Posto', field: 'postoName', sortable: true },
  { name: 'Nome do supervisor', field: 'userName', sortable: true },
  { name: 'Data da Visita', field: 'dataMarcada', sortable: true },
  { name: 'Hora Inicial Percurso', field: 'dataInicioPercurso', sortable: true },
  { name: 'Hora Final Percurso', field: 'dataFinalPercurso', sortable: true },
  { name: 'Hora Inicial Visita', field: 'dataInicio', sortable: true },
  { name: 'Hora Final Visita', field: 'dataFinal', sortable: true },
  { name: 'Km Inicial', field: 'kmInicial', sortable: true },
  { name: 'Km Final', field: 'kmFinal', sortable: true },
  { name: 'Km Total', field: 'kmTotal', sortable: true },
  { name: 'Descrição', field: 'descricao', sortable: true },
  { name: 'Observação', field: 'observacao', sortable: true },
  { name: 'Status da Rota', field: 'statusRota', sortable: true },
];

export const AcompanhamentoClientes: React.FC = () => {
  const { empresaPrincipal } = useAuth();

  const [isSelectedSupervisor, setIsSelectedSupervisor] = useState(false);
  const [dateInit, setDateInit] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [clienteIdSelect, setClienteIdSelect] = useState<number[]>([]);
  const [clientes, setClientes] = useState<Clientes[]>([]);
  const [userIdSelect, setUserIdSelect] = useState(-1);
  const [usersVig, setUsersVig] = useState<UsuarioBD[]>([]);
  const [usersAll, setUsersAll] = useState<UsuarioBD[]>([]);
  const [listHist, setListHist] = useState<ItemTable[]>([]);
  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dateInit === '' || dateEnd === '') return;
    setIsLoading(true);
    api
      .get(`/rotas?dataIni=${dateInit}&dataFim=${dateEnd}`)
      .then((resp) => {
        const rotasHist: GetRotaComplete[] = resp.data;
        // console.log(rotasHist);
        const vistApr = rotasHist.filter(
          (it) =>
            it.status !== 'A Aprovar' &&
            it.status !== 'Rejeitada' &&
            it.status !== 'Reprovada' &&
            it.Historico.length > 0,
        );
        // console.log(vistApr);

        const objItem: ItemTable[] = [];
        vistApr.forEach((item) => {
          const find = item.Historico[0];
          if (find) {
            objItem.push({
              atividade: '',
              dataFinal: moment(
                !find.timeFim ? find.createdAt : find.timeFim,
              ).format('HH:mm'),
              dataInicio: moment(
                !find.timeIni ? find.updatedAt : find.timeIni,
              ).format('HH:mm'),
              imagem: find.image
                ? `https://app-qrseguranca-ambiente.s3.amazonaws.com/${find.image}`
                : '',
              justificativa: item.justificativa,
              kmInicial: item.justificativa.split('|')[2],
              kmFinal: item.justificativa.split('|')[3],
              kmTotal: (parseInt(item.justificativa.split('|')[3]) - parseInt(item.justificativa.split('|')[2])).toString(),
              dataInicioPercurso: item.justificativa.split('|')[0],
              dataFinalPercurso: item.justificativa.split('|')[1],
              observacao: find.observacao,
              postoName: item.Visita.Posto.name.toUpperCase(),
              status: find.image ? 'FEITO' : 'FEITO SEM IMAGEM',
              statusRota:
                item.status === 'Aprovada' ? 'A iniciar' : item.status,
              userName: '',
              userId: item.supervisorId,
              postoId: item.Visita.Posto.id,
              visitaId: Number(item.visitaId),
              dataMarcada: moment(item.data).format('DD/MM/YYYY'),
              clientId: item.Visita.Posto.clienteId,
              clientName: '',
              dateAprov: !item.approvedDate
                ? ''
                : moment(item.approvedDate).format('DD/MM/YYYY'),
              userIdAprov: !item.approvedBy ? -1 : item.approvedBy,
              userNameAprov: '',
              descricao: find.descricao,
            });
          } else {
            objItem.push({
              atividade: '',
              dataFinal: '',
              dataInicio: '',
              imagem: '',
              justificativa: item.justificativa, // histFilter[0].justificativa,
              observacao: '',
              postoName: item.Visita.Posto.name.toUpperCase(),
              status:
                item.status === 'Aprovada' ? 'A INICIAR' : 'NÃO REALIZADA',
              statusRota:
                item.status === 'Aprovada' ? 'A Iniciar' : item.status,
              userName: '',
              userId: item.supervisorId,
              kmInicial: item.justificativa.split('|')[2],
              kmFinal: item.justificativa.split('|')[3],
              kmTotal: (parseInt(item.justificativa.split('|')[3]) - parseInt(item.justificativa.split('|')[2])).toString(),
              dataInicioPercurso: item.justificativa.split('|')[0],
              dataFinalPercurso: item.justificativa.split('|')[1],
              postoId: item.Visita.Posto.id,
              visitaId: Number(item.visitaId),
              dataMarcada: moment(item.data).format('DD/MM/YYYY'),
              clientId: item.Visita.Posto.clienteId,
              clientName: '',
              dateAprov: !item.approvedDate
                ? ''
                : moment(item.approvedDate).format('DD/MM/YYYY'),
              userIdAprov: !item.approvedBy ? -1 : item.approvedBy,
              userNameAprov: '',
              descricao: '',
            });
          }
          if (item.type === 'intercorrencia') {
            item.Historico.forEach((hit) => {
              objItem.push({
                atividade: 'INTERCORRÊNCIA',
                dataFinal: moment(
                  !hit.timeIni ? hit.createdAt : hit.timeIni,
                ).format('HH:mm'),
                dataInicio: moment(
                  !hit.timeFim ? hit.updatedAt : hit.timeFim,
                ).format('HH:mm'),
                imagem: hit.image
                  ? `https://app-qrseguranca-ambiente.s3.amazonaws.com/${hit.image}`
                  : '',
                justificativa: hit.justificativa,
                observacao: hit.observacao,
                kmInicial: item.justificativa.split('|')[2],
                kmFinal: item.justificativa.split('|')[3],
                kmTotal: (parseInt(item.justificativa.split('|')[3]) - parseInt(item.justificativa.split('|')[2])).toString(),
                dataInicioPercurso: item.justificativa.split('|')[0],
                dataFinalPercurso: item.justificativa.split('|')[1],
                postoName: item.Visita.Posto.name.toUpperCase(),
                status: hit.image ? 'FEITO' : 'FEITO SEM IMAGEM',
                statusRota:
                  item.status === 'Aprovada' ? 'A iniciar' : item.status,
                userName: '',
                userId: item.supervisorId,
                postoId: item.Visita.Posto.id,
                visitaId: Number(item.visitaId),
                dataMarcada: moment(item.data).format('DD/MM/YYYY'),
                clientId: item.Visita.Posto.clienteId,
                clientName: '',
                dateAprov: !item.approvedDate
                  ? ''
                  : moment(item.approvedDate).format('DD/MM/YYYY'),
                userIdAprov: !item.approvedBy ? -1 : item.approvedBy,
                userNameAprov: '',
                descricao: hit.descricao,
              });
            });
          }
        });
        setListHist(objItem);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dateEnd, dateInit]);

  const getClients = useCallback(() => {
    api
      .get(
        `/clientes/empresa?empresaId=${empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : '0'
        }`,
      )
      .then((response) => {
        const resp: Clientes[] = response.data;
        // console.log(resp);
        setClientes(
          resp.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          }),
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [empresaPrincipal]);

  const getUsers = useCallback(async () => {
    const usersGet = await api.get(
      `/allUsers/${empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );
    const usersResp: UsuarioBD[] = usersGet.data;
    // console.log(usersAll);
    setUsersVig(
      usersResp
        .filter((item) => item.acesso === '2')
        .sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        }),
    );
    setUsersAll(usersResp);
  }, [empresaPrincipal]);

  const getNameCliente = useCallback(
    (id: number) => {
      const find = clientes.find((i) => i.id === String(id));
      if (find) {
        return find.name;
      }
      return '';
    },
    [clientes],
  );

  const getNameUser = useCallback(
    (id: number) => {
      const find = usersAll.find((i) => i.id === id);
      if (find) {
        return find.nome;
      }
      return '';
    },
    [usersAll],
  );

  useEffect(() => {
    getUsers();
    getClients();
  }, [getClients, getUsers]);

  const handlePDF = useCallback(
    (listHistVist: ItemTable[]) => {
      const listPDF: ItemPDF[] = [];
      listHistVist.forEach((item) => {
        const index = listPDF.findIndex((i) => i.visitaId === item.visitaId);
        if (index === -1) {
          listPDF.push({
            visitaId: item.visitaId,
            postoName: item.postoName,
            dataMarcada: item.dataMarcada,
            status: item.statusRota,
            dataAprov: item.dateAprov,
            userAprov: item.userNameAprov,
            Tarefas: [
              {
                fin: item.dataFinal,
                init: item.dataInicio,
                justificativa: item.justificativa,
                name: item.atividade,
                obervacao: item.observacao,
                status: item.status,
                userName: item.userName,
              },
            ],
          });
        } else {
          listPDF[index].Tarefas.push({
            fin: item.dataFinal,
            init: item.dataInicio,
            justificativa: item.justificativa,
            name: item.atividade,
            obervacao: item.observacao,
            status: item.status,
            userName: item.userName,
          });
        }
      });

      const doc = new jsPDF();  // eslint-disable-line

      // doc.addFileToVFS('Montserrat-Regular.ttf', MontserratRegular);
      // doc.addFileToVFS('Montserrat-Medium.ttf', MontserratMedium);
      // doc.addFileToVFS('Montserrat-SemiBold.ttf', MontserratSemiBold);
      // doc.addFont('Montserrat-Regular.ttf', 'Montserrat', 'normal');
      // doc.addFont('Montserrat-Medium.ttf', 'Montserrat', 'medium');
      // doc.addFont('Montserrat-SemiBold.ttf', 'Montserrat', 'bold');

      // doc.setFont('Montserrat', 'normal');

      doc.setFontSize(10);
      doc.setTextColor(2, 2, 2);
      doc.setFontType('bold');

      let spacement = 20;
      let page = 1;

      listPDF.forEach((item, index) => {
        if (spacement + 40 > 280) {
          doc.addPage();
          spacement = 20; // Reinicia o espaçamento no topo da nova página
        }

        // Cabeçalho principal
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`${item.postoName}`, 20, spacement);
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text(`Data: ${item.dataMarcada}  |  Status: ${item.status}`, 20, spacement + 8);

        if (item.dataAprov || item.userAprov) {
          doc.setFont("helvetica", "italic");
          doc.text(`Aprovado em: ${item.dataAprov || '-'} por ${item.userAprov || '-'}`, 20, spacement + 15);
        }

        spacement += 10;

        let task = item.Tarefas[0];

        if (spacement + 40 > 280) {
          doc.addPage();
          spacement = 20;
          page += 1;
        }
        // Seção de tarefa
        doc.setFont("helvetica", "normal");
        spacement += 8;

        // Criando tabela para detalhes da tarefa
        const taskDetails = [
          ["Hora inicial Percurso", task.justificativa.split("|")[0] || "-"],
          ["Hora inicial Percurso", task.justificativa.split("|")[1] || "-"],
          ["Hora inicial Visita", task.init || "-"],
          ["Hora final Visita", task.fin || "-"],
          ["Km Inicial", task.justificativa.split("|")[2] || "-"],
          ["Km Final", task.justificativa.split("|")[3] || "-"],
          ["Km Total", (parseInt(task.justificativa.split("|")[3]) - parseInt(task.justificativa.split("|")[2])).toString() || "-"],
          ["Status", task.status || "-"],
          ["Supervisor", task.userName || "-"],
        ];

        let startY = spacement;
        doc.setFontSize(10);
        taskDetails.forEach(([label, value]) => {
          doc.setFont("helvetica", "bold");
          doc.text(`${label}:`, 20, startY);
          doc.setFont("helvetica", "normal");
          doc.text(value, 60, startY);
          startY += 5;
        });

        spacement = startY + 3; // Redução do espaçamento entre as tarefas
        doc.line(20, spacement, 190, spacement); // Linha divisória entre tarefas
        spacement += 15;
      });

      doc.autoPrint();
      doc.output('dataurlnewwindow');
    },
    [clienteIdSelect, getNameCliente, getNameUser, userIdSelect],
  );

  const listHistMemo: ItemTable[] = useMemo(() => {
    let list = listHist.map((item) => {
      const find = usersAll.find((it) => it.id === item.userId);
      const findAprov = usersAll.find((it) => it.id === item.userIdAprov);
      const findCli = clientes.find((it) => {
        return it.id === String(item.clientId);
      });
      const obj = item;
      if (find) {
        obj.userName = find.nome;
      }
      if (findAprov) {
        obj.userNameAprov = findAprov.nome;
      }
      if (findCli) {
        obj.clientName = findCli.name;
      }
      return obj;
    });

    if (clienteIdSelect.length > 0) {
      console.log("LISTA1", clienteIdSelect, list)
      let listaFiltrada;
      listaFiltrada = list.filter(
        it => clienteIdSelect.map(String).includes(it.clientId.toString()),
      );
      console.log("LISTA2", clienteIdSelect, listaFiltrada)
      return listaFiltrada;

    }
    return list;
  }, [listHist, userIdSelect, clienteIdSelect, usersAll, clientes]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = Number(event.target.value);

    setClienteIdSelect((prev) =>
      prev.includes(selectedValue)
        ? prev.filter((id) => id !== selectedValue) // Remove se já existir
        : [...prev, selectedValue] // Adiciona se não existir
    );
  };

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Acompanhamento de Visitas de Postos</TitlePage>

        <Options>
          <div>
            <button
              type="button"
              className={!isSelectedSupervisor ? 'selected' : 'notSelected'}
              onClick={() => {
                setIsSelectedSupervisor(false);
                setUserIdSelect(-1);
              }}
            >
              Clientes
            </button>
          </div>
          <div>
            <button
              type="button"
              className={isSelectedSupervisor ? 'selected' : 'notSelected'}
              onClick={() => {
                setIsSelectedSupervisor(true);
                setClienteIdSelect([]);
              }}
            >
              Supervisores
            </button>
          </div>
        </Options>

        <Filter>
          <ContainerInputDate>
            <input
              type="date"
              value={dateInit}
              onChange={(event) => {
                setDateInit(event.target.value);
              }}
            />
            <input
              type="date"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
            />
          </ContainerInputDate>
          {isSelectedSupervisor ? (
            <select
              value={userIdSelect}
              onChange={(event) => {
                setUserIdSelect(Number(event.target.value));
              }}
            >
              <option value={-1}>Todas os supervisores</option>
              {usersVig.map((it) => (
                <option key={it.id} value={it.id}>
                  {it.nome}
                </option>
              ))}
            </select>
          ) : (
            <SelectClients
              clientes={clientes}
              clienteIdSelect={clienteIdSelect}
              onChange={setClienteIdSelect}
            />
          )}
        </Filter>
        <Table
          header={headers}
          body={listHistMemo.filter(
            (e, i) => i >= indexInitPg && i <= indexEndPg && !isLoading,
          )}
          tableId="TableHistoricRotas"
        />
        {isLoading && (
          <Animeted>
            <FiRefreshCw size={40} className="animate-spin" />
            <p>Carregando ...</p>
          </Animeted>
        )}

        <PaginationComponent
          arrayStateFiltered={listHistMemo}
          setIndexPagination={(indexInit, indexEnd) => {
            setIndexInitPg(indexInit);
            setIndexEndPg(indexEnd);
          }}
        />
        <Actions>
          <ButtonPDF>
            <button type="button" onClick={() => handlePDF(listHistMemo)}>
              PDF
            </button>
          </ButtonPDF>
          <NewImportExel
            fileName="RelatorioAnalitico"
            tableId="TableHistoricRotas"
          />
        </Actions>
      </Container>
    </>
  );
};
